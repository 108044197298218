import {useContext, useEffect, useState} from "react";

import {TabItem} from "./Marketplace";
import {BuildingDescAndImage} from "../cards/BuildingDescAndImage";
import {MapchamberProduction} from "./MapchamberProduction";
import {CityConfigContext} from "../../index";


export function MapChamber({level, hideUpgradeMenu}) {
  const { cityProductionStats, cityBonus } = useContext(CityConfigContext);

  const [troopReq, setTroopReq] = useState();

  const [activeTab, setActiveTab] = useState("overview");

  const handleTabChange = (tab) => {
    if(tab === "overview"){
      hideUpgradeMenu(true)
    }else{
      hideUpgradeMenu(false)
    }
    setActiveTab(tab);
  };




  useEffect(() => {

    const fetchTroops = async () => {
      const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/troops/req`, {
        credentials: "include"
      });

      const data = await res.json();
      if(cityProductionStats){
        data.forEach((item) => {


          const bonus = cityBonus.troop_training_speed_bonus  / 100

          item.seconds_to_build = Math.round(item.seconds_to_build * (1 - bonus));

        })
      }

      setTroopReq(data);
    };
    fetchTroops();
  }, []);





  if (!troopReq) {
    return <div className="text-lg mb-4">Loading...</div>;
  }

  return (
    <div>
      <div className="mb-4">
        <ul className="flex border-b">
          <TabItem
              label="Overview"
              active={activeTab === "overview"}
              onClick={() => handleTabChange("overview")}
          />
          <TabItem
              label="Production"
              active={activeTab === "production"}
              onClick={() => handleTabChange("production")}
          />

        </ul>
      </div>


      {activeTab === "overview" && <BuildingDescAndImage type="Mapchamber" isDetails={true}/> }
      {activeTab === "production" && <MapchamberProduction level={level} troopReq={troopReq}/> }

    </div>
  );
}
