import { useContext, useEffect, useState } from "react";
import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import LootBagIcon from "../../images/LootBag.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../index";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive";
import { ScoutResponsive } from "../../images/troops/ScoutResponsive";
import { CatapultResponsive } from "../../images/troops/CatapultResponsive";

export function AttackDetailsCard(props) {
  const { userInfo } = useContext(UserContext);
  const {
    attackDetails: {
      attackerCityname,
      defenderCityname,
      troopAttackedWithAmount,
      attackerUsername,
      defenderUsername,
      ignore_troops,
      scouting,
      reinforcement,
    },
    attackerCasualties,
    defenderCasualties,
    resources,
    defenderTroops,
    canCarryResAmount,
    palace_effect,
    eventText,
  } = props.data;

  const [totalStolen, setTotalStolen] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalStolen(
      resources.wheat + resources.wood + resources.stone + resources.iron
    );
  }, []);

  return (
    <div className="h-full mt-2 flex w-full">
      <div className="w-full sm:overflow-x-auto text-sm sm:text-sm md:text-base lg:text-lg xl:text-xl">
        <div className="">
          <h2 className="text-xl font-bold mb-4 text-center">
            {reinforcement
              ? "Reinforcements"
              : scouting
              ? "Scouting details"
              : "Attack Details"}
          </h2>
          {eventText !== null && <div className="mb-4">{eventText}</div>}
          {/*Medium and big screen*/}
          <div className="hidden xs:block">
            <div className="grid grid-cols-1 gap-8">
              <div className="flex flex-col items-center">
                <table className="table-auto  mb-2 border-collapse">
                  <thead className="bg-mainLightBrown1">
                    <tr>
                      <th
                        className={`border border-black px-2 md:px-4 ${
                          reinforcement
                            ? "bg-mainReinforcement2"
                            : "bg-mainAttack3"
                        }  text-white`}
                      >
                        {reinforcement ? "Reinforcer" : "Attacker"}
                      </th>
                    </tr>
                    <tr className="">
                      <th className="border border-black px-2 md:px-4">
                        <h3
                          className="text-lg font-semibold mb-2 text-cyan-500"
                          onClick={() =>
                            navigate(
                              `/profile/${
                                attackerUsername === userInfo.username
                                  ? `me`
                                  : attackerUsername
                              }`
                            )
                          }
                        >
                          {attackerCityname}
                        </h3>
                      </th>
                      <th className="border border-black  px-2 md:px-4">
                        <ClubmanResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <SpearmanResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <AxemanResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <FounderIconResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <GuardianResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <WarlordResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <ScoutResponsive />
                      </th>
                      <th className="border border-black px-2 md:px-4">
                        <CatapultResponsive />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-mainLightBrown1">
                    <tr>
                      <td className="border border-black px-2 md:px-4">
                        Troops
                      </td>
                      {Object.entries(troopAttackedWithAmount).map(
                        ([troop, amount]) => (
                          <td
                            key={troop}
                            className="text-center border border-black px-2 md:px-4"
                          >
                            {amount}
                          </td>
                        )
                      )}
                    </tr>
                    {palace_effect && (
                      <tr>
                        <td className="border border-black px-2 md:px-4">
                          Distracted
                        </td>
                        {Object.entries(palace_effect).map(
                          ([troop, amount]) => (
                            <td
                              key={troop}
                              className="text-center border border-black px-2 md:px-4"
                            >
                              {amount}
                            </td>
                          )
                        )}
                      </tr>
                    )}
                    {}
                    {!reinforcement && (
                      <tr>
                        <td className="border border-black px-2 md:px-4">
                          Casualties
                        </td>
                        {Object.entries(attackerCasualties).map(
                          ([troop, amount]) => (
                            <td
                              key={troop}
                              className="text-center border border-black px-2 md:px-4"
                            >
                              {amount}
                            </td>
                          )
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>

                {!reinforcement && (
                  <div className="flex">
                    {/*LOOT INFO*/}
                    <div className="flex mr-2">
                      <WheatIconResponsive />
                      <div>{resources.wheat}</div>
                    </div>

                    <div className="flex mr-2">
                      <WoodIconReponsive />
                      <div>{resources.wood}</div>
                    </div>

                    <div className="flex mr-2">
                      <StoneIconResponsive />
                      <div>{resources.stone}</div>
                    </div>

                    <div className="flex mr-2">
                      <IronIconResponsive />
                      <div>{resources.iron}</div>
                    </div>

                    <div className="flex">
                      <img
                        src={LootBagIcon}
                        alt="ff"
                        className="w-[30px] h-[30px]"
                      />
                      <div>
                        {totalStolen}/ {canCarryResAmount}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {ignore_troops && (
                <h4 className="text-center">Raider token used!</h4>
              )}

              {/*DEFENDER TABLE*/}

              {!reinforcement && (
                <div className="flex flex-col items-center">
                  <table className="table-auto  mb-6 border-collapse ">
                    <thead className="bg-mainLightBrown1">
                      <tr>
                        <th className="border border-black px-2 md:px-4 bg-mainDefend3 text-white">
                          Defender
                        </th>
                      </tr>
                      <tr>
                        <th className="border border-black px-2 md:px-4">
                          <h3
                            className="text-sm font-semibold mb-2 text-cyan-500"
                            onClick={() =>
                              navigate(
                                `/profile/${
                                  defenderUsername === userInfo.username
                                    ? `me`
                                    : defenderUsername
                                }`
                              )
                            }
                          >
                            {defenderCityname}
                          </h3>
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <ClubmanResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <SpearmanResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <AxemanResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <FounderIconResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <GuardianResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <WarlordResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <ScoutResponsive />
                        </th>
                        <th className="border border-black px-2 md:px-4">
                          <CatapultResponsive />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-mainLightBrown1">
                      <tr>
                        <td className=" border border-black px-2 md:px-4">
                          Troops
                        </td>
                        {Object.entries(defenderTroops).map(
                          ([troop, amount]) => (
                            <td
                              key={troop}
                              className=" text-center border border-black px-2 md:px-4"
                            >
                              {amount === null ? "????" : amount}
                            </td>
                          )
                        )}
                      </tr>
                      <tr>
                        <td className="border border-black px-2 md:px-4">
                          Casualties
                        </td>
                        {Object.entries(defenderCasualties).map(
                          ([troop, amount]) => (
                            <td
                              key={troop}
                              className=" text-center border border-black px-2 md:px-4"
                            >
                              {amount === null ? "????" : amount}
                            </td>
                          )
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          {/*Small sreens*/}
          <div className="block xs:hidden">
            {/*ATTACK CARD*/}
            <div
              className={`${
                reinforcement ? "bg-mainReinforcement2" : "bg-mainAttack3"
              }  p-2`}
            >
              <h4 className="text-white text-center ">
                {reinforcement ? "Reinforcer" : "Attacker"}
              </h4>
              <div className="bg-mainLightBrown1 text-center p-2 rounded">
                <h4
                  className="mb-2 text-cyan-600"
                  onClick={() =>
                    navigate(
                      `/profile/${
                        attackerUsername === userInfo.username
                          ? `me`
                          : attackerUsername
                      }`
                    )
                  }
                >
                  City: {attackerCityname}
                </h4>

                <h4>{reinforcement ? "Troops" : "Casualties/Troops"}</h4>
                <div className="grid grid-cols-2 place-items-center">
                  <div className="flex">
                    <ClubmanResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop1}</>
                      ) : (
                        <>
                          {attackerCasualties.troop1}/
                          {troopAttackedWithAmount.troop1}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <SpearmanResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop2}</>
                      ) : (
                        <>
                          {attackerCasualties.troop2}/
                          {troopAttackedWithAmount.troop2}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <AxemanResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop3}</>
                      ) : (
                        <>
                          {attackerCasualties.troop3}/
                          {troopAttackedWithAmount.troop3}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <FounderIconResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop4}</>
                      ) : (
                        <>
                          {attackerCasualties.troop4}/
                          {troopAttackedWithAmount.troop4}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <GuardianResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop5}</>
                      ) : (
                        <>
                          {attackerCasualties.troop5}/
                          {troopAttackedWithAmount.troop5}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <WarlordResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop6}</>
                      ) : (
                        <>
                          {attackerCasualties.troop6}/
                          {troopAttackedWithAmount.troop6}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <ScoutResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop7}</>
                      ) : (
                        <>
                          {attackerCasualties.troop7}/
                          {troopAttackedWithAmount.troop7}
                        </>
                      )}
                    </h4>
                  </div>
                  <div className="flex">
                    <CatapultResponsive />
                    <h4 className="ml-2">
                      {reinforcement ? (
                        <>{troopAttackedWithAmount.troop8}</>
                      ) : (
                        <>
                          {attackerCasualties.troop8}/
                          {troopAttackedWithAmount.troop8}
                        </>
                      )}
                    </h4>
                  </div>
                </div>
                {palace_effect && (
                  <div className="mt-2 border-t border-black">
                    Distracted by Caligula's Palace
                    <div className="grid grid-cols-3 place-items-center">
                      <div className="flex">
                        <ClubmanResponsive />
                        <h4 className="ml-2">{palace_effect.troop1}</h4>
                      </div>
                      <div className="flex">
                        <SpearmanResponsive />
                        <h4 className="ml-2">{palace_effect.troop2}</h4>
                      </div>
                      <div className="flex">
                        <AxemanResponsive />
                        <h4 className="ml-2">{palace_effect.troop3}</h4>
                      </div>
                      <div className="flex">
                        <FounderIconResponsive />
                        <h4 className="ml-2">{palace_effect.troop4}</h4>
                      </div>
                      <div className="flex">
                        <GuardianResponsive />
                        <h4 className="ml-2">{palace_effect.troop5}</h4>
                      </div>
                      <div className="flex">
                        <WarlordResponsive />
                        <h4 className="ml-2">{palace_effect.troop6}</h4>
                      </div>
                      <div className="flex">
                        <ScoutResponsive />
                        <h4 className="ml-2">{palace_effect.troop7}</h4>
                      </div>
                      <div className="flex">
                        <CatapultResponsive />
                        <h4 className="ml-2">{palace_effect.troop8}</h4>
                      </div>
                    </div>
                  </div>
                )}

                {!reinforcement && (
                  <>
                    <h4 className="text-center mt-2 font-semibold mt-2 border-t border-black">
                      Loot
                    </h4>
                    <div className="grid grid-cols-2 place-items-center  mb-2">
                      <div className="flex mr-2">
                        <WheatIconResponsive />
                        <div>{resources.wheat}</div>
                      </div>

                      <div className="flex mr-2 ">
                        <WoodIconReponsive />
                        <div>{resources.wood}</div>
                      </div>

                      <div className="flex mr-2">
                        <StoneIconResponsive />
                        <div>{resources.stone}</div>
                      </div>

                      <div className="flex mr-2">
                        <IronIconResponsive />
                        <div>{resources.iron}</div>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <img
                        src={LootBagIcon}
                        alt="ff"
                        className="w-[20px] h-[20px]"
                      />
                      <div>
                        {totalStolen}/ {canCarryResAmount}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {ignore_troops && (
              <h4 className="text-center">Raider token used!</h4>
            )}

            {/*DEFENDER CARD*/}
            {!reinforcement && (
              <div className="bg-mainDefend3 mt-4 p-2">
                <h4 className="text-center text-white">Defender</h4>

                <div className="text-center bg-mainLightBrown1 p-2 rounded">
                  <h4
                    className="mb-2 text-cyan-600"
                    onClick={() =>
                      navigate(
                        `/profile/${
                          defenderUsername === userInfo.username
                            ? `me`
                            : defenderUsername
                        }`
                      )
                    }
                  >
                    City: {defenderCityname}
                  </h4>
                  <h4>Casualties/Troops</h4>
                  <div className="grid grid-cols-2 place-items-center">
                    <div className="flex">
                      <ClubmanResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop1 !== null ? (
                          <>
                            {defenderCasualties.troop1}/{defenderTroops.troop1}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <SpearmanResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop2 !== null ? (
                          <>
                            {defenderCasualties.troop2}/{defenderTroops.troop2}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <AxemanResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop3 !== null ? (
                          <>
                            {defenderCasualties.troop3}/{defenderTroops.troop3}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <FounderIconResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop4 !== null ? (
                          <>
                            {defenderCasualties.troop4}/{defenderTroops.troop4}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <GuardianResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop5 !== null ? (
                          <>
                            {defenderCasualties.troop5}/{defenderTroops.troop5}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <WarlordResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop6 !== null ? (
                          <>
                            {defenderCasualties.troop6}/{defenderTroops.troop6}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <ScoutResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop7 !== null ? (
                          <>
                            {defenderCasualties.troop7}/{defenderTroops.troop7}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                    <div className="flex">
                      <CatapultResponsive />
                      <h4 className="ml-2">
                        {defenderCasualties.troop8 !== null ? (
                          <>
                            {defenderCasualties.troop8}/{defenderTroops.troop8}
                          </>
                        ) : (
                          "????"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {palace_effect && (
            <div className="text-center">
              Attacking army distracted by Caligula's Palace.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
