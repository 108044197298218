import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage.jsx";
import { UpgradeBuilding } from "./Components/town/UpgradeBuilding.jsx";
import { TownPage } from "./pages/TownPage.jsx";
import { BuildingMenuPage } from "./pages/BuildingMenuPage.jsx";
import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import Cookies from "js-cookie";
import { ResourcePage } from "./pages/ResourcePage.jsx";
import { ResourceBox } from "./Components/resource/ResourceBox.jsx";
import { NotificationsPage } from "./pages/NotificationsPage";
import { MapPage } from "./pages/MapPage";
import { RegisterPage } from "./pages/RegisterPage";
import { HighScoresPage } from "./pages/HighScoresPage";
import { ProfilePage } from "./pages/ProfilePage";
import "./App.css";
import { CityActivityPage } from "./pages/CityActivityPage";
import { NotificationDetailsPage } from "./pages/NotificationDetailsPage";
import { FixedLayout } from "./FixedLayout";
import { Homepage } from "./pages/Homepage";
import { MessagePage } from "./pages/MessagePage";
import { MessageDetailsCard } from "./Components/cards/MessageDetailsCard";
import { AllianceHomePage } from "./pages/AllianceHomePage";
import { AlliancePublicPage } from "./pages/AlliancePublicPage";
import { AchievementsPage } from "./pages/AchievementsPage";
import React from "react";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TimerProvider } from "./Components/contexts/timerContext";
import { AboutPage } from "./pages/AboutPage";
import { GuidePage } from "./pages/GuidePage";
import { BugReportPage } from "./pages/BugReportPage";
import { SettingsPage } from "./pages/SettingsPage";
import PrivateRoute from "./PrivateRoute";
import { DiscordLogin } from "./pages/DiscordLogin";
import { CityStatsPage } from "./pages/CityStatsPage.jsx";
import { GameUpdatesPage } from "./pages/GameUpdatesPage.jsx";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<Application />);

export const WarehouseContext = createContext("");
export const AuthContext = createContext("");

export const CityConfigContext = createContext("");
export const NotificationContext = createContext("");
export const CityMapContext = createContext("");
export const UserContext = createContext("");
export const AllianceContext = createContext("");
export const CasinoContext = createContext("");
export const SkillContext = createContext("");

function Application() {
  const [warehouse, setWarehouse] = useState({
    wood: 0,
    wheat: 0,
    stone: 0,
    iron: 0,
  });

  // Use state to fetch config from client, save in localstorage

  const [cityProductionStats, setCityProductionStats] = useState();
  const [cityBonus, setCityBonus] = useState();
  const [cityName, setCityName] = useState();
  const [cityResourceFields, setCityResourceFields] = useState();
  const [cityTownCenter, setCityTownCenter] = useState();
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [inCityId, setInCityId] = useState(0);
  const [userSettings, setUserSettings] = useState();
  const [cityAbilities, setCityAbilities] = useState([]);

  const [middleCords, setMiddleCords] = useState();
  const [troops, setTroops] = useState();
  const [userInfo, setUserInfo] = useState();
  const [notificationDetailsId, setNotificationDetailsId] = useState();
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState();
  const [cityData, setCityData] = useState(true);
  const [tradersCount, setTradersCount] = useState(0);
  const [allianceRole, setAllianceRole] = useState();
  const [allianceData, setAllianceData] = useState();
  const [allianceInviteList, setAllianceInviteList] = useState();
  const [playerQuest, setPlayerQuest] = useState();
  const [personalAllianceInvites, setPersonalAllianceInvites] = useState();
  const [cityRestrictions, setCityRestrictions] = useState({
    hasBarracks: false,
  });
  const [hasFinnishedTutorial, setHasFinnishedTutorial] = useState(true);
  const [selectedResField, setSelectedResField] = useState();
  const [selectedBuilding, setSelectedBuilding] = useState();
  const [currentCasinoCoins, setCurrentCasinoCoins] = useState();
  const [maxCasinoCoins, setMaxCasinoCoins] = useState();
  const [blackJackDetails, setBlackJackDetails] = useState();
  const [showNewLootChestModal, setShowNewLootChestModal] = useState(false);
  const [canMakeFoundersAmount, setCanMakeFoundersAmount] = useState(0);
  const [socketState, setSocketState] = useState();
  const [showSpinner, setShowSpinner] = useState(true);
  const [authToken, setAuthToken] = useState("");
  const [skillInfo, setSkillInfo] = useState();
  const [citySpecialTroops, setCitySpecialTroops] = useState({
    canMakeWarlord: false,
    canMakeGuardian: false,
  });
  const [insta_builds_left, set_insta_builds_left] = useState(0);
  const [last_instacomplete, setLast_instacomplete] = useState(undefined);
  const [cityTokens, setCityTokens] = useState();
  const [cityTokenBoosts, setCityTokenBoosts] = useState();

  let socket;
  // SHould only run the useEffec after the user logs in

  useEffect(() => {
    if (!authToken) {
      let token = Cookies.get("authToken");
      setAuthToken(token);
    }
    // Get cookie
    // if jwt, then connect socket
    if (!socketState && authToken) {
      SetUpSocket();
    }
  }, [authToken]);

  function SetUpSocket() {
    socket = io(`${import.meta.env.VITE_SOCKETHOST}`, {
      withCredentials: true,
    });
    setSocketState(socket);

    socket.on("newRes", (newGametick) => {
      // SHould set the user in the context
      setWarehouse({
        ...newGametick,
        socket: socket,
      });
    });

    socket.on("city_buildings", (buildings) => {
      console.log("Got city building  update event");

      if (buildings) {
        const tempBuilding = localStorage.getItem("selectedTownBuilding");
        if (tempBuilding != null) {
          const parsedField = JSON.parse(tempBuilding);
          buildings.forEach((buildingItem) => {
            if (buildingItem.location === parsedField.location) {
              localStorage.setItem(
                "selectedTownBuilding",
                JSON.stringify(buildingItem)
              );
              setSelectedBuilding(buildingItem);
            }
          });
        }

        setCityTownCenter(buildings);

        const hasBarracks = buildings.some(
          (building) => building.building_name === "Barracks"
        );
        const hasMarketplace = buildings.some(
          (building) => building.building_name === "Marketplace"
        );

        setCityRestrictions((prevState) => ({
          ...prevState,
          hasBarracks: hasBarracks,
          hasMarketplace: hasMarketplace,
        }));
      }
    });

    socket.on("update_city_skills", (data) => {
      setSkillInfo(data);
    });

    socket.on("set_intabuilds", (data) => {
      set_insta_builds_left(data.canInstabuild);
      if (data.last_build) {
        setLast_instacomplete(new Date(data.last_build));
      }
    });

    socket.on("xp_gained", (xpObj) => {
      toast(`Gained ${xpObj.xp_gained} xp in ${xpObj.category} skilltree.`);
    });

    socket.on("loot_chest_rolled", (result) => {
      if (!result.chest_earned) {
        toast.error(`Rolled ${result.normal_roll}, no chest gained!`);
        return;
      }
      if (!result.chest_upgraded) {
        toast.success(
          `Rolled ${result.normal_roll}, chest earned, rolled ${result.upgrade_roll}, failed upgrade.`
        );
        return;
      }
      toast.success(
        `Rolled ${result.normal_roll}, chest earned. Rolled ${result.upgrade_roll}, chest upgraded`
      );
    });

    socket.on("city_tokens", (data) => {
      setCityTokens(data);
    });
    socket.on("change_city", (name, resourceFields, cityId) => {
      // Send the resource fields, the production rate and the city name
      setCityName(name);
      if (resourceFields) {
        resourceFields.sort((a, b) => a.location - b.location);
        resourceFields.forEach((item) => (item.status = item.level));
        resourceFields.sort((a, b) => a.location - b.location);
        setCityResourceFields(resourceFields);
      }
      const selectedField = localStorage.getItem("selectedResField");
      if (selectedField != null) {
        const parsedField = JSON.parse(selectedField);
        resourceFields.forEach((resField) => {
          if (resField.location === parsedField.location) {
            localStorage.setItem("selectedResField", JSON.stringify(resField));
            setSelectedResField(resField);
          }
        });
      }

      if (cityId) {
        setInCityId(cityId);
      }

      setTimeout(() => {
        setShowSpinner(false);
      }, 1700);
    });

    socket.on("get_city_buildings", (townCenter) => {
      townCenter.sort((a, b) => a.location - b.location);

      setCityTownCenter(townCenter);

      const hasBarracks = townCenter.some(
        (building) => building.building_name === "Barracks"
      );

      setCityRestrictions((prevState) => ({
        ...prevState,
        hasBarracks: hasBarracks,
      }));
    });
    socket.on("change_troops", (cityTroops) => {
      cityTroops.sort((a, b) => a.troop_id - b.troop_id);
      setTroops(cityTroops);
    });
    socket.on("update_prod_stats", (productionStats, bonuses) => {
      // Add on the res bonus for correct display

      productionStats.wheat_per_min = Math.round(
        productionStats.wheat_per_min +
          (productionStats.wheat_per_min / 100) * bonuses.wheat_bonus
      );
      productionStats.wood_per_min = Math.round(
        productionStats.wood_per_min +
          (productionStats.wood_per_min / 100) * bonuses.wood_bonus
      );
      productionStats.stone_per_min = Math.round(
        productionStats.stone_per_min +
          (productionStats.stone_per_min / 100) * bonuses.stone_bonus
      );
      productionStats.iron_per_min = Math.round(
        productionStats.iron_per_min +
          (productionStats.iron_per_min / 100) * bonuses.iron_bonus
      );

      setCityProductionStats(productionStats);
      setCityBonus(bonuses);
    });

    socket.on("new_notification", (notifications) => {
      setHasNewNotification(notifications.length);
    });

    socket.on("update_map", (middleX, middleY) => {
      setMiddleCords({
        x: middleX,
        y: middleY,
      });
    });

    socket.on("update_user_info", (newUser) => {
      setUserInfo(newUser);
    });

    socket.on("update_unread_messages", (newNumber) => {
      setNumberOfUnreadMessages(newNumber);
    });

    socket.on("alliance_role", (allianceRoleData) => {
      setAllianceRole(allianceRoleData);
    });

    socket.on("new_city_list", (cityList) => {
      setCityData(cityList);
    });

    socket.on("alliance_invite_list", (newList) => {
      setAllianceInviteList(newList);
    });

    socket.on("personal_alliance_invites", (inviteList) => {
      setPersonalAllianceInvites(inviteList);
    });

    socket.on("player_left_alliance", () => {
      console.log("a player has left your alliance");
    });

    socket.on("update_city_traders", (numbOfTraders) => {
      setTradersCount(numbOfTraders);
    });

    socket.on("alliance_base_details", (allianceBase) => {
      setAllianceData(allianceBase);
    });
    socket.on("kicked_alliance", () => {
      setAllianceData(undefined);
    });
    socket.on("quest_info", (data) => {
      setPlayerQuest(data);
    });
    socket.on("user_settings", (data) => {
      setUserSettings(data);
    });
    socket.on("tutorial_complete", (state) => {
      setHasFinnishedTutorial(state);
    });
    socket.on("casino_coins_update", (amountOfCoins) => {
      setCurrentCasinoCoins(amountOfCoins.casino_coins);
      setMaxCasinoCoins(amountOfCoins.max_casino_coins);
    });
    socket.on("blackJackDetails", (details) => {
      setBlackJackDetails(details);
    });
    socket.on("newLootChestModal", () => {
      setShowNewLootChestModal(true);
    });

    socket.on("token_boosts", (data) => {
      setCityTokenBoosts(data);
    });

    socket.on("count_create_founders", (canMake) => {
      setCanMakeFoundersAmount(canMake);
    });

    socket.on("update_city_abilities", (data) => {
      setCityAbilities(data);
    });

    socket.on("special_troops", (obj) => {
      setCitySpecialTroops(obj);
    });

    window.addEventListener("beforeunload", () => {
      if (socket) {
        socket.disconnect();
      }
    });
  }

  return (
    <TimerProvider>
      <WarehouseContext.Provider value={{ warehouse, setWarehouse }}>
        <SkillContext.Provider value={{ skillInfo }}>
          <CityConfigContext.Provider
            value={{
              cityProductionStats,
              cityTokenBoosts,
              cityBonus,
              cityTokens,
              setCityBonus,
              cityName,
              cityResourceFields,
              cityTownCenter,
              inCityId,
              troops,
              cityData,
              cityRestrictions,
              tradersCount,
              selectedResField,
              setSelectedResField,
              selectedBuilding,
              setSelectedBuilding,
              showNewLootChestModal,
              setShowNewLootChestModal,
              canMakeFoundersAmount,
              cityAbilities,
              citySpecialTroops,
              insta_builds_left,
              last_instacomplete,
            }}
          >
            <NotificationContext.Provider
              value={{
                hasNewNotification,
                setHasNewNotification,
                notificationDetailsId,
                setNotificationDetailsId,
              }}
            >
              <CityMapContext.Provider value={{ middleCords, setMiddleCords }}>
                <UserContext.Provider
                  value={{
                    userInfo,
                    numberOfUnreadMessages,
                    setNumberOfUnreadMessages,
                    playerQuest,
                    hasFinnishedTutorial,
                    socketState,
                    showSpinner,
                    setShowSpinner,
                    userSettings,
                  }}
                >
                  <AllianceContext.Provider
                    value={{
                      allianceRole,
                      personalAllianceInvites,
                      allianceData,
                      allianceInviteList,
                    }}
                  >
                    <CasinoContext.Provider
                      value={{
                        currentCasinoCoins,
                        blackJackDetails,
                        maxCasinoCoins,
                      }}
                    >
                      <AuthContext.Provider value={{ authToken, setAuthToken }}>
                        <BrowserRouter>
                          <FixedLayout>
                            <ToastContainer
                              autoClose={3500}
                              position="bottom-right"
                            />
                            <Routes>
                              <Route path={"/login"} element={<LoginPage />} />
                              <Route
                                path={"/login-discord"}
                                element={<DiscordLogin />}
                              />
                              <Route path={"/"} element={<Homepage />} />
                              <Route
                                path={"/bugReport"}
                                element={<BugReportPage />}
                              />
                              <Route path={"/about"} element={<AboutPage />} />
                              <Route path={"/guide"} element={<GuidePage />} />
                              <Route
                                path={"/news"}
                                element={<GameUpdatesPage />}
                              />
                              <Route
                                path={"/register"}
                                element={<RegisterPage />}
                              />

                              <Route element={<PrivateRoute />}>
                                <Route
                                  path="/highscores"
                                  element={<HighScoresPage />}
                                />
                                <Route path={"/map"} element={<MapPage />} />
                                <Route
                                  path={"/profile/:userFromPath"}
                                  element={<ProfilePage />}
                                />
                                <Route
                                  path={"/achievements"}
                                  element={<AchievementsPage />}
                                />
                                <Route
                                  path={"/alliance/:allianceTag"}
                                  element={<AlliancePublicPage />}
                                />
                                <Route
                                  path={"/cityActivity"}
                                  element={<CityActivityPage />}
                                />
                                <Route
                                  path={"/notificationDetails"}
                                  element={<NotificationDetailsPage />}
                                />
                                <Route
                                  path={"/message/details/:messageId"}
                                  element={<MessageDetailsCard />}
                                />
                                <Route
                                  path={"/allianceDetails"}
                                  element={<AllianceHomePage />}
                                />
                                <Route
                                  path={"/resources"}
                                  element={<ResourcePage />}
                                />
                                <Route path={"/town"} element={<TownPage />} />
                                <Route
                                  path={"/buildings/:buildingSpotId"}
                                  element={<BuildingMenuPage />}
                                />
                                <Route
                                  path={"/buildings/upgrade"}
                                  element={<UpgradeBuilding />}
                                />
                                <Route
                                  path={"/resourcefield/upgrade"}
                                  element={<ResourceBox />}
                                />
                                <Route
                                  path={"/notifications"}
                                  element={<NotificationsPage />}
                                />
                                <Route
                                  path={"/messages/all"}
                                  element={<MessagePage />}
                                />
                                <Route
                                  path={"/settings"}
                                  element={<SettingsPage />}
                                />
                                <Route
                                  path={"/cityStats"}
                                  element={<CityStatsPage />}
                                />
                                <Route
                                  path={"*"}
                                  element={<Navigate to="/" />}
                                />
                              </Route>
                            </Routes>
                          </FixedLayout>
                        </BrowserRouter>
                      </AuthContext.Provider>
                    </CasinoContext.Provider>
                  </AllianceContext.Provider>
                </UserContext.Provider>
              </CityMapContext.Provider>
            </NotificationContext.Provider>
          </CityConfigContext.Provider>
        </SkillContext.Provider>
      </WarehouseContext.Provider>
    </TimerProvider>
  );
}
