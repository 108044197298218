import { ResourceField } from "../Components/resource/ResourceField.jsx";

import "../assets/main.css";

export function ResourcePage() {
  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative  top-[10vh]">
        <ResourceField />
      </div>
    </div>
  );
}
