import { useContext } from "react";
import { CityConfigContext } from "..";
import InstaUpgradeTokenImage from "../images/tokens/FreeBuildingUpgradeToken.png";

export function InstaBuildTokenButton({ build }) {
  const { cityTokens } = useContext(CityConfigContext);
  return (
    <div>
      {cityTokens.structure_upgrade_count > 0 && (
        <div className="flex">
          <button
            onClick={build}
            className="bg-purple-500 hover:bg-purple-400 text-white font-semibold py-2 px-4 rounded transition duration-200 hover:scale-[105%]"
          >
            Build
          </button>
          <div>
            <div className="flex place-items-center">
              <div>{cityTokens.structure_upgrade_count}/1</div>
              <img
                src={InstaUpgradeTokenImage}
                alt="Upgrade token image "
                className="w-12 "
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
