import { useContext, useEffect, useState } from "react";
import { CityConfigContext, UserContext } from "../../index.jsx";
import { useNavigate } from "react-router-dom";
import { useTimerDispatch } from "../contexts/timerContext";
import { timerActions } from "../reducers/timerReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { parse } from "@fortawesome/fontawesome-svg-core";

export function CityList() {
  const { cityName, cityData, inCityId } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const { setShowSpinner } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  async function getData() {
    setIsLoading(true);
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/city/${inCityId}/list`,
      {
        credentials: "include",
      }
    );
    const cityList = await res.json();
    setData(cityList);

    setIsLoading(false);
  }

  useEffect(() => {
    if (inCityId && inCityId !== "0") {
      getData();
    }
  }, [inCityId]);

  useEffect(() => {
    if (cityData) {
      setData(cityData);
    }
  }, [cityData]);

  async function changeCity(cityId) {
    setShowSpinner(true);
    await fetch(`${import.meta.env.VITE_GAMEHOST}/api/city/change`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        cityId: inCityId,
        newCityId: cityId,
      }),
      credentials: "include",
    });

    dispatch({
      type: timerActions.CLEAR_ALL,
    });
    navigate("/town");
  }

  if (!data) {
    return <div>Loading..</div>;
  }

  return (
    <div className="bg-mainDarkBrown text-white p-2 min-w-[170px] sm:min-w-[170px] md:min-w-full lg:min-w-auto xl:min-w-auto z-[1000]">
      <div className="flex justify-center">
        <FontAwesomeIcon
          icon={faChartSimple}
          className="mr-2 hover:scale-[115%]"
          onClick={() => navigate("/cityStats")}
        />
        <h4 className="font-fantasy text-xs lg:text-sm xxl:text-lg text-white font-semibold mb-2 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-center">
          {cityName}
        </h4>
      </div>
      <div className="max-h-[19vh] overflow-auto bg-mainLightBrown1 p-2 ">
        <ul className="space-y-1 sm:space-y-2 md:space-y-2 lg:space-y-2 xl:space-y-2">
          {!isLoading &&
            data.map((city, index) => (
              <li
                key={index}
                onClick={() => changeCity(city.cityid)}
                className="w-full bg-mainLightYellow2 min-w-[50px] text-gray-800 text-sm  md:text-xxs  xl:text-sm p-1  rounded-md hover:bg-mainLightYellowHover hover:text-white transition-colors duration-200 cursor-pointer"
              >
                <div className="flex flex-wrap items-center justify-start">
                  {parseInt(inCityId) === city.cityid && (
                    <span className="inline-block bg-cyan-500 px-1 py-0.5 rounded-full self-start">
                      Current
                    </span>
                  )}
                  <h2 className="whitespace-normal mr-2">{city.cityname}</h2>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
